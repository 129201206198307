a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
button,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
body {
  height: 100%;
}
body {
  font-family: var(--font-one);
}
hr {
  margin: 0;
}
b,
strong {
  font-weight: 700;
}
i,
em {
  font-style: italic;
}
button {
  background-color: transparent;
}
p {
  font-family: var(--font-one);
  font-size: 18px;
  line-height: 24px;
  font-weight: 300;
}

main {
  overflow: hidden;
}

.cdk-overlay-container {
  .backdrop-light + .cdk-global-overlay-wrapper {
    .full-screen {
      max-width: 100vw !important;
      width: 100vw !important;
      height: 100vh !important;
    }
  }
}

// mat-dialog styles
.mat-dialog-container {
  max-width: 100% !important;
  padding: 0 !important;
  border-radius: 0 !important;
}

.swiper-pagination {
  padding-bottom: 30px;
}
/* target the active bullet */
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: var(--lat-white);
  opacity: 1;
}

/* target all bullets */
.swiper-pagination-bullet {
  border-radius: 0;
  background-color: transparent;
  border: 1px solid var(--lat-white);
  opacity: 1;
  width: min(4vw, 30px);
  height: min(4vw, 30px);
}

/**
  Hack to Bypass View Encapsulation on Angular Material Components.
  Used with Contact Form to style dropdown.
  See this article for details:
    https://material.angular.io/guide/customizing-component-styles#bypassing-encapsulation
**/
mat-form-field {
  .mat-form-field-underline {
    display: none;
  }
}
mat-select.input {
  padding-left: 0;
  .mat-select-arrow-wrapper {
    display: none;
  }
  .mat-select-trigger {
    top: 13px;
  }
  .mat-select-min-line {
    color: var(--lat-blue);
  }
}
mat-option {
  border: 1px solid #979797;
  .mat-option-text {
    color: var(--lat-blue);
  }
}
