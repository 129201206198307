// COLORS

:root {
  --lat-blue: #093e52;
  --lat-white: #ffffff;
  --lat-orange: #eaa45d;
  --lat-dk-orange: #de6a2e;
  --lat-cyan: #b4dad2;
  --lat-cyan-2: #9ccbc9;
  --lat-med-cyan: #7dc2be;
  --lat-dk-cyan: #53b6b2;

  --homes-blue: #008ebe; //TODO: LAT colors instead
  --homes-blue-hover: #006b90; //TODO: LAT colors instead
  --elevate-orange: #ff9e18; //TODO: LAT colors instead
  --elevate-orange-hover: #df870d; //TODO: LAT colors instead
  --lat-gray: #979797; //TODO: LAT colors instead
  --lat-dk-gray: #252628; //TODO: LAT colors instead
  --lat-light-gray: #d8d8d8; //TODO: LAT colors instead
  --lat-lighter-gray: #e8e8e8; //TODO: LAT colors instead
  --lat-charcoal: #302f2c; //TODO: LAT colors instead
  --lat-black: #000000; //TODO: LAT colors instead
}

// FONT FAMILIES

:root {
  --font-one: dashiell-bright, serif;
  --font-two: haboro-soft-extended, sans-serif;
}
