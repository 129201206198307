@import "~@angular/cdk/overlay-prebuilt.css";
@import "variables";
@import "common";

html {
  scroll-behavior: smooth;
  font-family: var(--font-one);
}

.tablet,
.desktop {
  display: none;
}

.mobile {
  @include mobile-only {
    display: initial;
  }

  @include tablet-landscape {
    display: none;
  }
}

.tablet {
  @include tablet-portrait {
    display: initial;
  }
}

.desktop {
  @include tablet-landscape {
    display: initial;
  }
}

// Homepage Form Fields

.front {
  .search {
    .mat-form-field {
      .mat-form-field-wrapper {
        margin-bottom: 8px;
        padding-bottom: 0;

        .mat-form-field-infix {
          padding: 12px 48px 13px 15px;
          border-radius: 4px;
          font-family: var(--font-two);

          &:after {
            content: url("/assets/icons/icon_wt_search.svg");
            position: absolute;
            width: 24px;
            height: 24px;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
          }

          .mat-form-field-label {
            top: 1.45em;
            left: 1em;
            font-size: 16px;
            line-height: 23px;
            font-weight: 300;
          }
        }

        .mat-form-field-subscript-wrapper,
        .mat-form-field-underline {
          display: none;
        }
      }

      &.mat-form-field-should-float {
        .mat-form-field-wrapper {
          .mat-form-field-label {
            display: none !important;
          }
        }
      }
    }
  }

  &.homes {

    .mat-form-field-infix,
    .mat-form-field-label {
      color: #fff;
    }

    .mat-form-field-infix {
      position: relative;
      background-color: rgba(0, 0, 0, 0.5);
      border: 1px solid #fff;
    }
  }

  &.elevate {

    .mat-form-field-infix,
    .mat-form-field-label {
      color: #000;
    }

    .mat-form-field-infix {
      border: 1px solid #000;

      &:after {
        filter: brightness(0) saturate(100%) invert(67%) sepia(97%) saturate(1597%) hue-rotate(344deg) brightness(100%) contrast(104%);
      }
    }
  }
}

// Header Form Fields

.header {
  .search {
    .mat-form-field {
      .mat-form-field-wrapper {
        margin-bottom: 30px;
        padding-bottom: 0;

        .mat-form-field-infix {
          padding: 12px 48px 13px 15px;
          border-radius: 4px;
          font-family: var(--font-two);
          color: #000;
          border: 1px solid #000;

          &:after {
            content: url("/assets/icons/icon_wt_search.svg");
            position: absolute;
            width: 24px;
            height: 24px;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            filter: brightness(0) saturate(100%) invert(40%) sepia(40%) saturate(6900%) hue-rotate(172deg) brightness(96%) contrast(101%);
          }

          .mat-form-field-label {
            top: 1.45em;
            left: 1em;
            font-size: 16px;
            line-height: 23px;
            font-weight: 300;
            color: #000;
          }
        }

        .mat-form-field-subscript-wrapper,
        .mat-form-field-underline {
          display: none;
        }
      }

      &.mat-form-field-should-float {
        .mat-form-field-wrapper {
          .mat-form-field-label {
            display: none !important;
          }
        }
      }
    }
  }
}

// Autocomplete Overlay

.cdk-overlay-container {
  .fyh-autocomplete {
    .heading {
      background-color: var(--lat-gray);
      color: #fff;
      font-size: 16px;
      font-weight: 700;
      line-height: 23px;
      padding: 6px 15px 7px;
    }

    .mat-option-text {
      font-family: var(--font-two);
      font-size: 16px;
      line-height: 23px;
      font-weight: 700;
    }
  }
}

// Google Maps Info Windows

.gm-style {
  .gm-style-iw-c {
    padding: 0;
    border-radius: 0;
    box-shadow: 0 2px 3px 0px rgb(0 0 0 / 30%);
  }

  .gm-style-iw-t {
    &:after {
      content: none;
    }

    .gm-style-iw-d {
      overflow: visible !important;
      max-height: none !important;
    }

    .gm-ui-hover-effect {
      top: 7px !important;
      right: 7px !important;
      width: auto !important;
      height: auto !important;
      opacity: 1;

      img {
        content: url("/assets/icons/icon_close.svg");
        filter: brightness(0) invert(1);
        margin: 0 !important;
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
}

.cluster-icon {
  div {
    font-size: 18px !important;
    font-family: var(--font-two) !important;
    font-weight: 700 !important;
    color: #fff !important;
    line-height: 51px !important;
  }
}

.gm-bundled-control {
  .gmnoprint {
    &>div {
      border-radius: 10px !important;
    }
  }
}

// Back to Top Mobile Button

.back-to-top {
  background-color: #3e3e3e;
  color: #fff;
  text-align: center;
  padding: 11px;
  font-size: 15px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: 600px) {
    display: none;
  }

  img {
    margin-right: 15px;
    filter: brightness(0) invert(1);
  }
}

.dropdown-wrapper {
  display: flex;
  justify-content: center;

  @media screen and (min-width: 960px) {
    display: none;
  }

  .button {
    padding: 1rem;
    background-color: var(--lat-black);
    color: var(--lat-white);
    width: 100%;
    height: 50px;
    font-family: inherit;
    cursor: pointer;
    text-align: left;

    span {
      .button-text {
        margin-left: 15px;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 22px;
        letter-spacing: 2px;
        font-weight: bold;
        top: -3px;
        position: relative;
        cursor: pointer;
      }
    }
  }
}

.dropdown-content {
  cursor: pointer;

  .dropdown-item {
    padding: 1rem;
    font-family: inherit;
    font-weight: bold;
    font-size: 16px;
    background-color: white;
    border: none;
    cursor: pointer;
    outline: none;
    border-bottom: 1px solid var(--lat-light-gray);
    cursor: pointer;
  }

  .dropdown-item:hover {
    background-color: var(--lat-lighter-gray);
  }
}

.centering-section {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;

  lat-paginated-callout-slider {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.nav-placeholder {
  height: 119px;

  @media screen and (min-width: 960px) {
    height: 86px;
  }
}


.amenities-swiper-container {
  swiper {
    flex-grow: 1;

    .swiper-slide {
      height: 254px;
      width: 300px;
    }
  }
}

.about-empower-swiper-container {
  swiper {
    .swiper-slide {
      height: 459px;
      width: 300px;
    }
  }
}

.home-swiper-root-container {
  swiper {
    .swiper-slide {
      width: 300px;
      height: 480px;
    }
  }
}

mat-spinner {
  svg {
    circle {
      stroke: var(--lat-blue) !important;
    }
  }
}