@import "variables";

// BREAKPOINTS

@mixin mobile-only {
  @media (max-width: 960px) {
    @content;
  }
}
@mixin tablet-portrait {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin tablet-landscape {
  @media (min-width: 960px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1200px) {
    @content;
  }
}

// ANIMATIONS

@keyframes homeHeroSlideIn {
  0% {
    transform: rotate(0) translateX(-60%);
  }
  100% {
    transform: rotate(-25deg) translateX(0);
  }
}

// BUTTONS
@mixin lat-button {
  background: var(--lat-blue);
  padding: 17px 15px 15px 15px;
  color: var(--lat-white);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 3px;
  line-height: 18px;
  font-family: var(--font-one);
  text-transform: uppercase;
  cursor: pointer;
}
